import React, { useState } from "react";
import { Select, Text, Space, Button, Stack } from "@mantine/core";
import { AC_DOWNLOAD_MAP } from "../../common/Const";

import "./Map.css";

interface MapSearchProps {
  onAction: (actionType: string, value: any) => void;
  year: string;
  month: string;
}

const MapSearchComponent: React.FC<MapSearchProps> = ({
  onAction,
  year,
  month,
}) => {
  return (
    <div className="map-search">
      <Stack spacing={4} align={"start"}>
        <Text weight={500}>Select Month </Text>
        <Select
          placeholder="Pick month"
          value={month}
          onChange={(value) => {
            onAction("AC_CHANGE_MONTH", value);
          }}
          data={[
            { value: "All", label: "All" },
            { value: "1", label: "January" },
            { value: "2", label: "February" },
            { value: "3", label: "March" },
            { value: "4", label: "April" },
            { value: "5", label: "May" },
            { value: "6", label: "June" },
            { value: "7", label: "July" },
            { value: "8", label: "August" },
            { value: "9", label: "September" },
            { value: "10", label: "October" },
            { value: "11", label: "November" },
            { value: "12", label: "December" },
          ]}
        />
      </Stack>
      <Stack spacing={4} align={"start"}>
        <Text weight={500}>Select Year</Text>
        <Select
          placeholder="Pick year"
          value={year}
          onChange={(value) => {
            onAction("AC_CHANGE_YEAR", value);
          }}
          data={[
            { value: "2021", label: "2021" },
            { value: "2022", label: "2022" },
            { value: "2023", label: "2023" },
            { value: "2024", label: "2024" },
          ]}
        />
      </Stack>

      <Button
        onClick={() => onAction(AC_DOWNLOAD_MAP, null)}
        style={{
          marginTop: "auto",
        }}
       className="download-map"
      >
        Download Map
      </Button>
    </div>
  );
};

export default MapSearchComponent;
