// import React, { useState } from "react";
// import "./navbar.component.scss";
// import navLogo from "../../assets/powered-by.png";
// import { AC_DOWNLOAD_MAP, AC_CHANGE_MONTH, AC_CHANGE_YEAR } from '../../common/Const'
// import { useMediaQuery } from "react-responsive";
// import { Select, Button, Text, Space } from '@mantine/core';

// const NavbarComponent = ({ onAction, year, month }: { onAction: any, year: string, month: string }) => {
//   const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1300px)" });

//   return (
//     <div className="cf-navbar">
//       <div className="cf-navbar__left">
//         <a href="https://www.coachsafely.org">
//           <img src={navLogo} alt="" className="cf-navbar__left--logo" />
//         </a>
//       </div>

//       <div className="cf-navbar__links">

//         <Text weight={500}>Select Month {" "}</Text>
//         <Space w="md" />
//         <Select

//           placeholder="Pick month"
//           value={month}
//           onChange={(value) => {
//             debugger
//             onAction(AC_CHANGE_MONTH, value)
//           }}
//           data={[
//             { value: 'All', label: 'All' },
//             { value: '1', label: 'January' },
//             { value: '2', label: 'February' },
//             { value: '3', label: 'Mars' },
//             { value: '4', label: 'April' },
//             { value: '5', label: 'May' },
//             { value: '6', label: 'June' },
//             { value: '7', label: 'August' },
//             { value: '8', label: 'July' },
//             { value: '9', label: 'September' },
//             { value: '10', label: 'October' },
//             { value: '11', label: 'November' },
//             { value: '12', label: 'December' },

//           ]

//           }></Select>
//         <Space w="md" />
//         <Text weight={500}>Select Year</Text>
//         <Space w="md" />
//         <Select
//           placeholder="pick year"
//           value={year}
//           onChange={(value) => {
//             onAction(AC_CHANGE_YEAR, value)
//           }}
//           data={[
//             { value: '2021', label: '2021' },
//             { value: '2022', label: '2022' },
//             { value: '2023', label: '2023' },
//             { value: '2024', label: '2024' },
//           ]

//           }></Select>
//         <Space w="md" />
//         <Button onClick={() =>
//           onAction(AC_DOWNLOAD_MAP, null)
//         }>
//           Download Map
//         </Button>

//       </div>

//     </div >
//   );
// };

// export default NavbarComponent;

import React, { useState } from "react";
import "./navbar.component.scss";
import { useMediaQuery } from "react-responsive";
import {
  faBars,
  faChevronRight,
  faChevronDown,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import navLogo from "../../assets/powered-by.png";

const NavbarComponent = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1300px)" });
  const [isOpen, setIsOpen] = useState(false);
  const [openedSubmenu, setOpenedSubmenu] = useState(0);

  return (
    <div className="cf-navbar">
      <div className="cf-navbar__left">
        <a href="https://www.coachsafely.org">
          <img src={navLogo} alt="" className="cf-navbar__left--logo" />
        </a>
      </div>

      {isTabletOrMobile && !isOpen ? (
        <FontAwesomeIcon icon={faBars} onClick={() => setIsOpen(true)} />
      ) : null}

      {isOpen && isTabletOrMobile ? <div className="nav-overlay"></div> : null}

      {isOpen || !isTabletOrMobile ? (
        <div className="cf-navbar__links">
          {isTabletOrMobile ? (
            <div
              className="cf-navbar__links--link d-inline-block"
              onClick={() => setIsOpen(false)}
            >
              <FontAwesomeIcon icon={faXmark} className="xmark-icon" />
            </div>
          ) : null}
          <div className="d-inline ch-menu">
            <a
              className="cf-navbar__links--link"
              onClick={() =>
                isTabletOrMobile
                  ? setOpenedSubmenu(openedSubmenu === 1 ? 0 : 1)
                  : null
              }
            >
              about
              {isTabletOrMobile ? (
                <FontAwesomeIcon
                  icon={openedSubmenu === 1 ? faChevronDown : faChevronRight}
                />
              ) : null}
            </a>
            <ul
              className={
                isTabletOrMobile ? "ch-sub-menu-responsive" : "ch-sub-menu"
              }
            >
              {!isTabletOrMobile || openedSubmenu === 1 ? (
                <>
                  <a href="https://www.coachsafely.org/foundation/">
                    foundation
                  </a>
                  <a href="https://www.coachsafely.org/leadership/">
                    leadership
                  </a>
                  <a href="https://www.coachsafely.org/donate">donate</a>
                </>
              ) : null}
            </ul>
          </div>

          <div className="d-inline ch-menu">
            <a
              className="cf-navbar__links--link"
              onClick={() =>
                isTabletOrMobile
                  ? setOpenedSubmenu(openedSubmenu === 2 ? 0 : 2)
                  : null
              }
              href="https://coachsafely.org/education/"
            >
              Eduction
              {isTabletOrMobile ? (
                <FontAwesomeIcon
                  icon={openedSubmenu === 2 ? faChevronDown : faChevronRight}
                />
              ) : null}
            </a>

            <ul
              className={
                isTabletOrMobile ? "ch-sub-menu-responsive" : "ch-sub-menu"
              }
            >
              {!isTabletOrMobile || openedSubmenu === 2 ? (
                <>
                  <a href="https://www.coachsafely.org/thecourse/">
                    The Course
                  </a>
                  <a href="https://www.coachsafely.org/the-course-benefits/">
                    Benefits
                  </a>
                  <a href="https://www.coachsafely.org/sudden-cardiac-arrest-guides/">
                    Sudden Cardiac Arrest Guides
                  </a>
                </>
              ) : null}
            </ul>
          </div>

          <div className="d-inline ch-menu">
            <a
              className="cf-navbar__links--link"
              onClick={() =>
                isTabletOrMobile
                  ? setOpenedSubmenu(openedSubmenu === 2 ? 0 : 2)
                  : null
              }
              href="https://coachsafely.org/policy/"
            >
              policy
              {isTabletOrMobile ? (
                <FontAwesomeIcon
                  icon={openedSubmenu === 2 ? faChevronDown : faChevronRight}
                />
              ) : null}
            </a>

            <ul
              className={
                isTabletOrMobile ? "ch-sub-menu-responsive" : "ch-sub-menu"
              }
            >
              {!isTabletOrMobile || openedSubmenu === 2 ? (
                <>
                  <a href="https://coachsafely.org/policy/health-equity/">
                    Health Equity
                  </a>
                  <a href="https://coachsafely.org/policy/legislation/">
                    Legislation
                  </a>
                  <a href="https://coachsafely.org/guidelines-and-best-practices/">
                    Guidelines and Best Practices
                  </a>
                </>
              ) : null}
            </ul>
          </div>

          <div className="d-inline ch-menu">
            <a
              className="cf-navbar__links--link"
              onClick={() =>
                isTabletOrMobile
                  ? setOpenedSubmenu(openedSubmenu === 2 ? 0 : 2)
                  : null
              }
              href="https://coachsafely.org/research/"
            >
              research
              {isTabletOrMobile ? (
                <FontAwesomeIcon
                  icon={openedSubmenu === 2 ? faChevronDown : faChevronRight}
                />
              ) : null}
            </a>

            <ul
              className={
                isTabletOrMobile ? "ch-sub-menu-responsive" : "ch-sub-menu"
              }
            >
              {!isTabletOrMobile || openedSubmenu === 2 ? (
                <>
                  <a href="https://coachsafely.org/coachsafely-findings/">
                    CoachSafely Findings
                  </a>
                  <a href="https://coachsafely.org/injury-surveillance/">
                    Injury Surveillance
                  </a>
                  <a href="https://coachsafely.org/literature-review/">
                    Literature Review
                  </a>
                </>
              ) : null}
            </ul>
          </div>

          <div className="d-inline ch-menu">
            <a
              className="cf-navbar__links--link"
              onClick={() =>
                isTabletOrMobile
                  ? setOpenedSubmenu(openedSubmenu === 2 ? 0 : 2)
                  : null
              }
            >
              Resources
              {isTabletOrMobile ? (
                <FontAwesomeIcon
                  icon={openedSubmenu === 2 ? faChevronDown : faChevronRight}
                />
              ) : null}
            </a>

            <ul
              className={
                isTabletOrMobile ? "ch-sub-menu-responsive" : "ch-sub-menu"
              }
            >
              {!isTabletOrMobile || openedSubmenu === 2 ? (
                <>
                  <a href="https://www.coachsafely.org/news-blog">Blog</a>
                </>
              ) : null}
            </ul>
          </div>

          <a href="/" className="cf-navbar__links--link">
            Coaches roster
          </a>
          <a
            href="https://app.coachsafely.org"
            className={
              isTabletOrMobile
                ? "cf-navbar__links--link login-responsive"
                : "cf-navbar__links--link cf-navbar__links--link-primary"
            }
            onClick={() => setIsOpen(false)}
          >
            Login
          </a>
        </div>
      ) : null}
    </div>
  );
};

export default NavbarComponent;
