import React, { useEffect, useRef, useState } from "react";
import Map from "./components/map/Map";
import { MantineProvider } from "@mantine/core";
import "./App.css";
import {
  AC_DOWNLOAD_MAP,
  AC_CHANGE_MONTH,
  AC_CHANGE_YEAR,
} from "./common/Const";

import HeaderComponent from "./components/header/header.component";
import NavbarComponent from "./components/navbar/navbar.component";
import MapSearchComponent from "./components/map/map-search.component";
import Announcement from "./components/announcement/index";

const MAX_IMAGE_SIDE = 200;

export default function App() {
  const mapRef: any = useRef(null);
  const [month, setMonth] = useState("All");
  const [year, setYear] = useState("2024");
  const onNavAction = (action: string, value: string) => {
    debugger;
    if (action === AC_DOWNLOAD_MAP) mapRef.current.downloadScreen();
    if (action === AC_CHANGE_MONTH) setMonth(value);
    if (action === AC_CHANGE_YEAR) setYear(value);
  };
  const renderMain = () => {
    return (
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <div className="App">
          <HeaderComponent />
          <NavbarComponent />
          <div className="mapContainer">
            <MapSearchComponent
              onAction={onNavAction}
              month={month}
              year={year}
            />
            <Map
              ref={mapRef}
              data={null}
              onSelect={(item: any) => {}}
              month={month}
              year={year}
            />
          </div>
          <div>{/* <FooterComponent /> */}</div>
        </div>
        <Announcement
          title="Notice"
          subtitle="County by county, state by state, we continue to grow our certified coach footprint to bring safer practices to every field and court across where kids play sports across the country. Select each year to view progress and see which locations are prioritizing youth sports safety and protecting more of their young athletes. Zoom in to see stats by county."
        />
      </MantineProvider>
    );
  };

  return renderMain();
}
