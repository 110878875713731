import * as React from "react";
import { useEffect, useState } from "react";
import { animationKeyframes } from "./animations";
import { styles } from "./styles";

export interface ComponentProps {
  /** The title of the announcement. */
  title: string;

  /** The general card text on the announcement. */
  subtitle: string;

  /**
   * The image source string used on the left side of the image.
   * Use a square image for the best results.
   * Dimensions are 68x68 pixels.
   */
  imageSource?: string;

  /** The link used when the announcement is clicked. */
  link?: string;

  /**
   * An optional property specifying the number of days
   * the cookie will live before the announcement is
   * shown again to a user.
   */
  daysToLive?: number;

  /**
   * The number of seconds a user has to
   * keep the page open before the
   * announcement is shown.
   */
  secondsBeforeBannerShows?: number;

  /**
   * Change the size of the close icon shown
   * in the top right corner of the announcement.
   */
  closeIconSize?: number;

  /**
   * Change the duration of the fade-in animation (defaults to 1000ms)
   */
  animateInDuration?: number;

  /**
   * Change the duration of the fade-out animation (defaults to 300ms)
   */
  animateOutDuration?: number;
}

const Announcement: React.FunctionComponent<ComponentProps> = ({
  title,
  subtitle,
  imageSource,
  link,
  closeIconSize,
  animateInDuration,
  animateOutDuration,
}) => {
  const [showBanner, setShowBanner] = useState<boolean>(true);
  const [showAnimation, setShowAnimation] = useState<boolean>(true);

  /**
   * Executed when the announcement
   * is clicked and opens the link
   * in a new window.
   */
  const openLink = (): void => {
    window.open(link, "_blank");
  };

  /**
   * Executes when the close icon
   * is clicked. Creates the fadeout
   * animation and hides the banner
   * from the page.
   */
  const fadeOut = (): void => {
    setShowAnimation(false);

    setTimeout(() => {
      setShowBanner(false);
    }, animateOutDuration || 300);
  };

  return showBanner ? (
    <>
      <style children={animationKeyframes} />
      <div
        style={{
          ...styles.bannerCard,
          ...styles.animationStyles({
            showAnimation,
            animateInDuration,
            animateOutDuration,
          }),
        }}
      >
        {/* <img
          onClick={openLink}
          style={styles.imageStyle}
          src={imageSource}
          alt="Banner"
        /> */}
        <div
          // onClick={openLink}
          style={styles.textWrapper}
        >
          <h3 style={styles.titleStyle}>{title}</h3>
          <p style={styles.subtitleStyle}>{subtitle}</p>
        </div>

        <div onClick={fadeOut}>
          <svg
            style={{ ...styles.closeIcon, fontSize: closeIconSize, cursor: "pointer" }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="icon icon-tabler icons-tabler-outline icon-tabler-x"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M18 6l-12 12" />
            <path d="M6 6l12 12" />
          </svg>
        </div>
      </div>
    </>
  ) : null;
};

Announcement.defaultProps = {
  daysToLive: 7,
  secondsBeforeBannerShows: 5,
  closeIconSize: 30,
  animateInDuration: 1000,
  animateOutDuration: 300,
} as Partial<ComponentProps>;

export default Announcement;
