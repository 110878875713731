import React from "react";
import "./header.component.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faInstagram,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";

const HeaderComponent = () => {
  return (
    <div className="cf-header">
      <div className="cf-header__icons">
        <a
          className="cf-header__icons--link"
          href="https://twitter.com/coachsafely"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            marginLeft: 15,
          }}
        >
          <FontAwesomeIcon
            icon={faTwitter}
            className="cf-header__icons--icon"
          />
        </a>
        <a
          className="cf-header__icons--link"
          href="https://www.facebook.com/coachsafely"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faFacebookF}
            className="cf-header__icons--icon"
          />
        </a>
        <a
          className="cf-header__icons--link"
          href="https://www.instagram.com/coachsafely"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faInstagram}
            className="cf-header__icons--icon"
          />
        </a>
      </div>
      <div className="cf-header__right">
        {/*<div className="cf-header__right--link" onClick={useCourse}>*/}
        {/*    <FontAwesomeIcon icon={faAnglesRight} className="arrow-icon" />*/}
        {/*    access the course*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default HeaderComponent;
