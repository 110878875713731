export const mapboxgl_accessToken =
  "pk.eyJ1IjoiZ3VpcXVpbWFuIiwiYSI6ImNqbWozNXg0eTBhamkzcG83M3NrNGNzZ2MifQ.oYUb8TAMBluF7DW7MdKVuA";
// export const API_BASE_URL = 'https://console.situateme.uxxu.io';
export const API_BASE_URL = "http://localhost:3001";

export const tenantConfig = {
  formFields: [
    {
      name: "name",
      type: "text",
      placeholder: "Name",
      label: "Name",
    },
    {
      name: "description",
      type: "text",
      placeholder: "Description",
      label: "Description",
    },
    {
      name: "logo",
      type: "text",
      placeholder: "Logo url",
      label: "Logo",
    },
    {
      name: "active",
      type: "checkbox",
      label: "Active",
    },
  ],
  id: "tenant_id",
};

export const botsConfig = {
  name: "Bot",
  columns: [
    {
      headerName: "Id",
      field: "bot_id",
      sortable: true,
      filter: true,
      cellRenderer: "cellRender",
      width: 100,
    },
    { headerName: "Name", field: "bot_name", sortable: true, filter: true },
    { headerName: "Key", field: "bot_key", sortable: true, filter: true },
    {
      headerName: "Tenant",
      field: "tenantname",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Active",
      field: "active",
      sortable: true,
      filter: true,
      cellRenderer: "activeRender",
    },
    {
      headerName: "Bot Url",
      field: "bot_url",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Buttons",
      field: "",
      cellRenderer: "btnsRender",
      deleteFN: "delete_bot",
    },
  ],
  formFields: [
    {
      name: "tenant_id",
      type: "select tenant",
      label: "Tenant",
    },
    {
      name: "bot_name",
      type: "text",
      placeholder: "Bot Name",
      label: "Bot Name",
    },
    {
      name: "bot_key",
      type: "text",
      placeholder: "Bot Key",
      label: "Bot Key",
    },
    {
      name: "bot_url",
      type: "text",
      placeholder: "Bot url",
      label: "Bot url",
    },
    {
      name: "active",
      type: "checkbox",
      label: "Active",
    },
  ],
  function: { add: "add_bot", edit: "edit_bot", delete: "delete_bot" },
};

export const SystemUserConfig = {
  name: "System User",
  columns: [
    {
      headerName: "Id",
      field: "user_id",
      sortable: true,
      filter: true,
      cellRenderer: "cellRender",
      width: 100,
    },
    {
      headerName: "Name",
      field: "user_email",
      sortable: true,
      filter: true,
      cellRenderer: "cellRender",
    },
    {
      headerName: "Password",
      field: "user_password",
      sortable: true,
      filter: true,
      cellRenderer: "passwordRender",
    },
    {
      headerName: "Tenant Name",
      field: "tenantname",
      sortable: true,
      filter: true,
      cellRenderer: "cellRender",
    },
    {
      headerName: "Active",
      field: "active",
      sortable: true,
      filter: true,
      cellRenderer: "activeRender",
    },
    {
      headerName: "Buttons",
      field: "",
      cellRenderer: "btnsRender",
    },
  ],
  formFields: [
    {
      name: "tenant_id",
      type: "select tenant",
      label: "Tenant",
    },
    {
      name: "user_email",
      type: "email",
      placeholder: "example@gmail.com",
      label: "Email",
    },
    {
      name: "user_password",
      type: "password",
      placeholder: "Password",
      label: "Password",
    },
    {
      name: "active",
      type: "checkbox",
      label: "Active",
    },
  ],
  function: {
    add: "add_systemUser",
    edit: "edit_systemUser",
    delete: "delete_systemUser",
  },
};

export const botsUsersConfig = {
  name: "Bot User",
  columns: [
    {
      dndSource: true,
      headerName: "Last Name",
      field: "lastname",
      sortable: true,
      filter: true,
      cellRenderer: "cellRender",
    },
    {
      headerName: "First Name",
      field: "firstname",
      sortable: true,
      filter: true,
      cellRenderer: "cellRender",
    },
    {
      headerName: "gender",
      field: "gender",
      sortable: true,
      filter: true,
      cellRenderer: "cellRender",
    },
    {
      headerName: "phone",
      field: "phone",
      sortable: true,
      filter: true,
      cellRenderer: "cellRender",
    },
    {
      headerName: "Tenant ID",
      field: "tenant_id",
      sortable: true,
      filter: true,
      cellRenderer: "cellRender",
    },
    {
      headerName: "Identifier",
      field: "identifier",
      sortable: true,
      filter: true,
      cellRenderer: "cellRender",
    },
    {
      headerName: "Test",
      field: "test",
      sortable: true,
      filter: true,
      cellRenderer: "activeRender",
    },
    {
      headerName: "Buttons",
      field: "",
      cellRenderer: "btnsRender",
    },
  ],
  formFields: [
    {
      name: "tenant_id",
      type: "select tenant",
      label: "Tenant",
    },
    {
      name: "lastname",
      type: "text",
      placeholder: "Last Name",
      label: "Last Name",
    },
    {
      name: "firstname",
      type: "text",
      placeholder: "First Name",
      label: "First Name",
    },
    {
      name: "gender",
      type: "text",
      placeholder: "Gender",
      label: "Gender",
    },
    {
      name: "phone",
      type: "text",
      placeholder: "Phone",
      label: "Phone",
    },
    {
      name: "email",
      type: "email",
      placeholder: "Email",
      label: "Email",
    },
    {
      name: "identifier",
      type: "text",
      placeholder: "Identifier",
      label: "Identifier",
    },
    {
      name: "test",
      type: "checkbox",
      label: "test",
    },
  ],
  function: {
    add: "add_botUser",
    edit: "edit_botUser",
    delete: "delete_botUser",
  },
};

export const botsUsersGroupConfig = {
  name: "Bot User Group",
  columns: [],
  formFields: [
    {
      name: "tenant_id",
      type: "select tenant",
      label: "Tenant",
    },
    {
      name: "parent_id",
      type: "select parent",
      placeholder: "Parent Name",
      label: "Parent",
    },
    {
      name: "name",
      type: "text",
      placeholder: "Name",
      label: "Name",
    },
    {
      name: "description",
      type: "text",
      placeholder: "Description",
      label: "Description",
    },
  ],
  function: {
    add: "add_botUserGroup",
    edit: "edit_botUserGroup",
    delete: "delete_botUserGroup",
    link: "link_users_groups",
  },
  id: "bot_users_groups_id",
};
