// @ts-nocheck
import * as React from "react";
import ReactMapGL, {
  Source,
  Layer,
  Popup,
  NavigationControl,
} from "react-map-gl";
import { Loader } from "@mantine/core";
import mapboxgl from "mapbox-gl";
import { mapboxgl_accessToken } from "../../settings/Config";
import exportAsPicture from "../../helper/download.helper";
import "./Map.css";
import FileSaver from "file-saver";
import LoaderComponent from "../loader/loader.cmp";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved

//const HOST = `https://96ioaipppc.execute-api.us-east-1.amazonaws.com/stage/api/map/`;
const HOST = `https://api.playsafelysports.com/api/map/`;
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

interface IMapStateInterface {
  location: any;
  viewport: {
    width: string;
    height: string;
    zoom: number;
    bearing: number;
    pitch: number;
    latitude?: number;
    longitude?: number;
  };
  hoverInfo: any;
  selectInfo: any;
  hoverFeature: any;
  selectFeature: any;
  loading: boolean;
}

interface IMapPropsInterface {
  data: any;
  onSelect: any;
  month: any;
  year: any;
}

export const dataLayer = {
  id: "county-heatmap",
  type: "fill",
  paint: {
    "fill-color": {
      property: "count",
      stops: [
        [0, "#ffffff"],
        [3, "#fff7ec"],
        [10, "#fee8c8"],
        [30, "#fdd49e"],
        [50, "#fdbb84"],
        [80, "#fc8d59"],
        [100, "#ef6548"],
        [200, "#d7301f"],
        [300, "#b30000"],
        [400, "#7f0000"],
      ],
    },
    "fill-opacity": 0.7,
  },
};

const bordersLayer = {
  id: "state-borders",
  type: "line",

  layout: {},
  paint: {
    "line-color": "black",
    "line-width": 0.5,
  },
};

const hoverLayer = {
  id: "county-hover",
  type: "fill",
  paint: {
    "fill-outline-color": "#484896",
    "fill-color": "#6e599f",
    "fill-opacity": 0.75,
  },
};

const selectLayer = {
  id: "county-selection",
  type: "fill",
  paint: {
    "fill-outline-color": "green",
    "fill-color": "greenyellow",
    "fill-opacity": 0.75,
  },
};

export default class MapComponent extends React.PureComponent<IMapPropsInterface> {
  public state: IMapStateInterface = {
    viewport: {
      width: "100%",
      height: "100%",
      longitude: -98.5795,
      latitude: 39.8283,
      zoom: window.innerWidth <= 768 ? 2.5 : 4,
      bearing: 0,
      pitch: 0,
    },
    location: {
      longitude: -98.5795,
      latitude: 39.8283,
    },
    selectedCounty: null,
    showPopup: true,
    loading: true,
  };

  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.month !== this.props.month ||
      nextProps.year !== this.props.year
    ) {
      this.setState({
        loading: true,
      });
    }
    return true;
  }
  public closeWindows = () => {
    window.close();
  };

  public onViewPortChange = (viewport: any) => {
    this.setState({
      viewport: Object.assign(viewport, { width: "100%", height: "100%" }),
    });
  };

  onHover = (event) => {
    const county = event.features && event.features[0];
    if (!county) return;
    //debugger;
    this.setState({
      selectedCounty: {
        longitude: event.lngLat.lng,
        latitude: event.lngLat.lat,
        id: county.properties.id,
        name: county.properties.name,
        count: county.properties.count,
        x: event.point.x,
        y: event.point.y,
      },
    });
  };

  downloadScreen = () => {
    const fileName = "Map.png";
    const mapboxCanvas = document.querySelector(".mapboxgl-canvas");
    mapboxCanvas.toBlob((blob) => {
      debugger;
      FileSaver.saveAs(blob, fileName);
    });
  };

  onMapData = (event) => {
    if (!event.source || event.source.type !== "geojson") return;
    debugger;
    console.log(event);
    this.setState({
      loading: false,
    });
  };

  public render() {
    const { viewport, selectedCounty, loading } = this.state;
    const { month, year } = this.props;
    const url = month == "All" ? `byyear/${year}` : `bymonth/${month}/${year}`;

    return (
      <div className="main-container">
        {loading ? (
          <LoaderComponent loading={loading} overlayLoading={true} />
        ) : (
          <></>
        )}
        <div className="map-container" ref={this.mapRef}>
          <ReactMapGL
            initialViewState={viewport}
            mapStyle="mapbox://styles/mapbox/dark-v10"
            mapboxAccessToken={mapboxgl_accessToken}
            onMouseMove={this.onHover}
            interactiveLayerIds={["county-heatmap"]}
            preserveDrawingBuffer={true}
            onData={this.onMapData}
          >
            <div
              style={{ position: "absolute", bottom: 8, right: 0, margin: 5 }}
            >
              <NavigationControl />
            </div>

            {/* <Source type="geojson" data="./map.json"> */}
            <Source type="geojson" data={`${HOST}${url}`}>
              <Layer {...dataLayer} />
              <Layer
                {...selectLayer}
                filter={["in", "id", selectedCounty ? selectedCounty.id : "1"]}
              />
            </Source>

            {/* {selectedCounty && (
              <Popup
                longitude={selectedCounty.longitude}
                latitude={selectedCounty.latitude}
                offset={[0, -10]}
                closeButton={false}
              >
                <div className="map-tooltip-container">
                  <div className="map-tooltip-title">
                    County {selectedCounty.name}
                  </div>
                  <div className="map-tooltip-item-container">
                    <div className="map-tooltip-item-label">Count:</div>
                    <div className="map-tooltip-item-value">
                      {selectedCounty.count}
                    </div>
                  </div>
                </div>
              </Popup>
            )} */}

            {selectedCounty && (
              <div
                className="tooltip"
                style={{
                  left: selectedCounty.x,
                  top: selectedCounty.y,
                }}
              >
                <div>County: {selectedCounty.name}</div>
                <div>Count {selectedCounty.count}</div>
              </div>
            )}
          </ReactMapGL>
        </div>
      </div>
    );
  }
}
