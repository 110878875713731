import React from "react";
import { Title, Space, Text } from "@mantine/core";
import logo from "./assets/logo-icon.png";
import "./loader.style.scss"
const LoaderComponent = ({ loading, overlayLoading }: { loading: boolean, overlayLoading: boolean }) => {
  return (
    <div
      className={
        overlayLoading
          ? "loader_container transparent-loader"
          : "loader_container"
      }
    >
      <img
        alt="Rotation Icon"
        className="rotate-Icon"
        src={logo}
        draggable="false"
        height={100}
      />
      {!overlayLoading ? (
        <>
          <Space h={50}></Space>
          <Title>Coach Safely</Title>
          <Text>Loading..</Text>
        </>
      ) : null}
    </div>
  );
};

export default LoaderComponent;
